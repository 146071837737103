"use client";
import * as React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioItem,
  DropdownMenuRadioGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";

type Checked = DropdownMenuCheckboxItemProps["checked"];

export default function MobileNavBar(props: any) {
 const [selectedItem, setSelectedItem] = React.useState<string>("Home");
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Menu className="text-purple-500" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="text-lg font-semibold">
          Ai Studying
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={selectedItem}
          onValueChange={setSelectedItem}
        >
          <DropdownMenuRadioItem value="Home">Home</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="Activity Bar">
            Activity Bar
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="Panel">Panel</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
