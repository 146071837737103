"use client";

import Link from "next/link";
import Image from "next/image";

import { Button } from "@/components/ui/button";

import MobileNavBar from "./MobileNavBar";
import { UserButton } from "../../../components/auth/user-button";
import { useCurrentUser } from "@/hooks/use-current-user";

export default function NavBar() {
  const user = useCurrentUser();
  return (
    <header className=" py-1 bg-gradient-to-r from-[#232526] to-[#414345] navbar-shadow ">
      <div className="container  mx-auto flex  md:flex-row items-center justify-between">
        <div className=" md:hidden lg:hidden ">
          <MobileNavBar />
        </div>
        <Link href="/">
          <Image
            src="/assets/logo.svg"
            alt="Logo"
            height={0}
            width={0}
            style={{ width: "90px", height: "auto" }}
            className="cursor-pointer "
            priority
          />
        </Link>

        <div className="hidden text-white sm:flex items-center gap-6 font-semibold ">
          <Link className="hover:text-gray-300 transition-colors" href="/ask-questions">
            Ask Questions
          </Link>
          <Link
            className="hover:text-gray-300 transition-colors"
            href="/categories"
          >
            Exams
          </Link>
          <Link
            className="hover:text-gray-300 transition-colors"
            href="/course"
          >
            Courses
          </Link>
        </div>
        <div className=" sm:flex">
          {user ? (
            <UserButton />
          ) : (
            <Button asChild className="bg-white text-black">
              <Link href="/sign-in">Let&apos;s Start</Link>
            </Button>
          )}
        </div>
      </div>
    </header>
  );
}
