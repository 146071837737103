"use client";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { signOut } from "next-auth/react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { User } from "lucide-react";
import { useCurrentUser } from "@/hooks/use-current-user";
import { ExitIcon } from "@radix-ui/react-icons";
import { ModeToggle } from "../toogle";
import { Label } from "@radix-ui/react-label";


export const UserButton = () => {

    const user=useCurrentUser();
  const handleSignOut = () => {
    signOut();
  };
  return (
    <div className=" ">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Avatar className="hover:bg-red-500 cursor-pointer   rounded-full transition duration-300">
            <AvatarImage src={user?.image || ""} />
            <AvatarFallback>
              <User size={24} />
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuSeparator />
          <div className=" mx-3 flex gap-10 ">
            <Label htmlFor="airplane-mode" className="font-semibold">Mood</Label>
            <ModeToggle />
          </div>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>Profile</DropdownMenuItem>
            <DropdownMenuItem>Billing</DropdownMenuItem>
            <DropdownMenuItem>Settings</DropdownMenuItem>
          </DropdownMenuGroup>

          <DropdownMenuSeparator />

          <DropdownMenuItem>Support</DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleSignOut}>
            Log out
            <ExitIcon className="w-5 h-5 ml-10  transform scale-x-[-1]" />
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
